var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "giftBox" }, [
      _c("div", { staticClass: "giftBox__box" }, [
        _c("div", { staticClass: "giftBox__photo" }, [
          _c("div", { staticClass: "photo__inset" }, [
            _c(
              "div",
              { staticClass: "photo__bg" },
              [
                _c(
                  "v-avatar",
                  { staticClass: "photo", attrs: { size: "168" } },
                  [_c("img", { attrs: { src: _vm.headImage } })]
                )
              ],
              1
            )
          ])
        ]),
        _vm._m(1),
        _c(
          "div",
          { staticClass: "giftBox__list" },
          _vm._l(_vm.giftItemCard, function(gift) {
            return _c(
              "div",
              { key: gift.GiftID, staticClass: "giftItemCard" },
              [
                _vm._m(2, true),
                _c("div", { staticClass: "info_area" }, [
                  _c("div", { staticClass: "main_group" }, [
                    _c("div", { staticClass: "base" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(gift.ProductName))
                      ]),
                      _c("div", { staticClass: "receiverMember" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(gift.GiverName) +
                            " (ID:$" +
                            _vm._s(gift.GiverMemberId) +
                            ") 贈\n                "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "control" }, [
                      gift.Enabled
                        ? _c("div", { staticClass: "control__enablefinish" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/歸戶平台/check.svg"),
                                alt: ""
                              }
                            }),
                            _vm._v(
                              "\n                  已完成啟用\n                "
                            )
                          ])
                        : _c(
                            "div",
                            {
                              staticClass: "control__enableBtn",
                              on: {
                                click: function($event) {
                                  return _vm.enableGiftHandler(gift)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                  立即啟用\n                "
                              )
                            ]
                          )
                    ])
                  ])
                ])
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page-header custom-container hidden-pad-down" },
      [
        _c("img", {
          staticClass: "img-title",
          attrs: { src: require("@/assets/header_member.svg"), alt: "" }
        }),
        _c("span", [_vm._v("我的禮物盒")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "giftBox__list__title" }, [
      _c("span", [_vm._v("我的禮物總覽")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pic_box" }, [
      _c("img", {
        attrs: { src: require("@/assets/會員相關/giftBox_item.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }